import React from 'react'
import { graphql } from 'gatsby'
import PageRoot from '../components/PageRoot'
import { renderCarouselItem } from '../utils'
import { useTranslation } from 'react-i18next'
import EventureGrid from '../components/EventureGrid'
import ContainerWithPadding from "../components/ContainerWithPadding"

const AllChannelPage = ({ data }) => {
  const { t } = useTranslation()

  const allChannels = data.eventure.channels
  return (
    <PageRoot title={t('label:channels')} showTitle>
      <ContainerWithPadding>
        <EventureGrid
          colSettings={{ xs: 24, sm: 12, md: 6, lg: 6 }}
          dataSource={allChannels}
          renderItem={renderCarouselItem}
        />
      </ContainerWithPadding>

    </PageRoot>
  )
}

export const query = graphql`
    query allChannels {
        eventure {
            channels(order_by: { name: asc }) {
                ...PreviewCardChannel
            }
        }
    }
`

export default AllChannelPage
